import SkipToContentButtonComponent from '@wix/thunderbolt-elements/src/components/SkipToContentButton/viewer/SkipToContentButton';


const SkipToContentButton = {
  component: SkipToContentButtonComponent
};


export const components = {
  ['SkipToContentButton']: SkipToContentButton
};

